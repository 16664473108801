import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire NextJS Developers`,
    text: "Leverage Exceptional Expertise",
    content: "Hire NextJS Developers to provide flawless user experiences, quick features, and the finest cost optimization for your custom application development.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire NextJS Developers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Next.js Developers As Per Your Needs',
    text: "Our full-stack Next JS Developers use their significant knowledge to create a masterpiece that comprehends your business.",
    list: [
      {
        title: 'Streamlined Infrastructure',
        text: "Our NextJS Developers are well-equipped with key tools and systems in line with current trends."
      },
      {
        title: 'Enhanced Performance',
        text: 'Hire NextJS developer who optimizes website performance through efficient rendering, reduced code, and enhanced SEO.'
      },
      {
        title: 'Improved Engagement',
        text: 'Hire Next.js developers to speed up your application, enhance engagement, and create a responsive and useful interface.'
      }
    ],
    image: ["hire-content-next.webp"],
    titleText: 'Full-stack Next js Developers',
    alt: 'Benefits of hiring Next js developers'
  }
  const commonContent2 = {
    title: 'Hire Next js Developers from Octet',
    text: "Leading companies trust Octet Design Studio to hire Next js developers to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire NextJS Developers In 48 Hours',
        text: 'Hire Next js developer to ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'StStay in control with detailed timesheet reports from our full-stack Next js developers to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our Nextjs development company commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Work with the dedicated full-stack Next js developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-next.webp"],
    titleText: 'Hire NextJS developer',
    alt: 'Hire NextJS developers from a leading NextJS development company'
  }
  const commonContent3 = {
    title: `3 Steps to Hire Next.js Developer`,
    text: "Experience the Octet advantage by hiring Next.js developers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: `Provide us with your project requirements, and we will share the resumes of our full-stack Next js developers for hire who best match your needs and business goals.`
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled Next.JS developers to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire NextJS developer, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-next.webp"],
    titleText: 'Hire Next.js developer',
    alt: 'Simple steps to hire Next js developer from Octet'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Hire the Best NextJS Development Company Advantage`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce NextJS Developers",
      content: "Our full-stack Next js developers use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire NextJS developers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Next js Developer for SaaS",
      content:
        "SaaS NextJS developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire Next.js developers who will be creative problem solvers and develop beautiful, functional apps and websites that enhance user experience.",
    },
    {
      title: "Enterprise Next.js Developer",
      content: "Hire Next JS developers who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our NextJS Experts will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire NextJS Developers for High-Performant Apps`,
    des: `Hire Next js Developer who are front-end development leaders and have created intuitive custom software solutions for clients worldwide.`,
    tabs: ["Static Site Generation ", "Server-Side Rendering", "API Integration", "Progressive Web Applications"],
    slides: [
      {
        title: 'Static Site Generation',
        desc1: "Hire a Next js Developer to create fast and SEO-friendly static websites by generating HTML at build time. Our full-stack Next js developers integrate content sources that deliver content to be pre-rendered. ",
        list: [
          'Improving performance',
          'Expertise in pre-rendered static pages',
          'Boosting Scalability'
        ]
      },
      {
        title: 'Server-Side Rendering',
        desc1: "Hire Next.js developers to implement complex logic on the server side and API calls before rendering the page. Our full-stack Next.js developers ensure the secure handling of sensitive data by keeping it on the server side and only sending necessary data to the client.",
        // desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Improving user experience',
          'Enhancing security',
          'Providing Real-time data'
        ]
      },
      {
        title: 'API Integration',
        desc1: "Hire NextJS developer to integrate REST or GraphQL APIs to facilitate data communication between the front and back ends. Our NextJS development company implements robust error handling to ensure the application handles any API failures gracefully.",
        // desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Delivering Seamless Integration',
          'Enhancing user interaction',
          'Simplifying backend development'
        ]
      },
      {
        title: 'Progressive Web Applications',
        desc1: "Hire Next.js developers to ensure your application is fully responsive and adapts to different screen sizes and orientations. Octet’s full-stack Next js developers optimize performance by minimizing JavaScript bundles, lazy loading images and components, and prefetching critical resources.",
        // desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Providing Offline capabilities',
          'Enhancing user engagement',
          'Increasing faster load times'
        ]
      }
    ],
    altOne: 'Static Site Generation',
    titleOne: 'Static Site Generation',
    altTwo: 'Server-Side Rendering',
    titleTwo: 'Server-Side Rendering',
    altThree: 'API Integration',
    titleThree: 'API Integration',
    altFour: 'Progressive Web Applications',
    titleFour: 'Progressive Web Applications',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire Next js developer who are passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'NextJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const Questions = [
    "Why should you Hire Next js Developer from Octet?",
    "How can you Hire NextJS Developer from Octet?",
    "Which companies Hire NextJS Developers?",
    "Do your Full-stack Next js Developers come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of full-stack Next js developers who specialize in crafting engaging and functional interfaces.`,
        `Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire Next js developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring Next.js developer from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring NextJS developer from us, onboard them to your project team and give them access to necessary resources.`,
      ]
    },
    {
      para: [
        `NextJS developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring Next js Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Next js Developer for Inclusive Solutions",
    para: 'The full-stack Next js developers of Octet build high-caliber software programs that are extremely useful.',
    content: [
      {
        title: "UX Consultation",
        text: "Our NextJS development company offers consultation services to help clients understand the benefits of Next.js and how it can be leveraged for their projects.",
      },
      {
        title: "Interactive UI Development",
        text: "Hire Next js developer to integrate design systems and style guides to maintain consistency and streamline the design-to-development workflow.",
      },
      {
        title: "UI UX Design",
        text: "Our full-stack Next js developers ensure the design is faithfully implemented and provides an exceptional user experience.",
      },
      {
        title: "Custom Web App Development",
        text: "Hire Next js developers to build bespoke web applications utilizing Next.js tailored to specific business needs and requirements.",
      },
      {
        title: "Rapid Prototyping",
        text: "Our NextJS development company creates interactive prototypes using Next.js to conduct user testing and gather feedback before final implementation.",
      },
      {
        title: "Usability Testing",
        text: "Hire NextJS developer to Implement usability testing to evaluate design variations and determine the most effective user interface elements and layouts.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire Next js Developer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of full-stack Next js developers who specialize in crafting engaging and functional interfaces. 
          
          Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire Next js developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire NextJS Developer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Next.js developer from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring NextJS developer from us, onboard them to your project team and give them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire NextJS Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "NextJS developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Do your Full-stack Next js Developers come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Next js Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }                                              
        `}
      </script>
    </Helmet>
      <Layout hire="Hire NextJS Developers">
        <div>
          <HireBanner data={banner} pageName="Hire UI/UX Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire NextJS Developer for Industry Specific`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our NextJS developers, hiring Next.js developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire NextJS Developers | NextJS Development Company"
    description="Looking to hire NextJS developers? Our full-stack Next js developers will help you to construct high-performance, leading-edge apps."
  />
);